<template>
  <vx-card>
    <div class="vx-row">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <vs-tabs>
            <vs-tab label="Billing CMI">
              <div class="tab-text">
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Branch ID</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="territory"
                      :options="optionTerr"
                      :multiple="false"
                      track-by="id"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="200"
                      :limit="5"
                      placeholder="Type to search"
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Inv Date From</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <datepicker :inline="false" v-model="invDateFrom"></datepicker>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Inv Date To</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <datepicker :inline="false" v-model="invDateTo"></datepicker>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Invoice No</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="InvNumber"
                      :options="optionInvNo"
                      :multiple="true"
                      track-by="code"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="200"
                      :limit="5"
                      placeholder="Type to search"
                      :custom-label="customLabel2"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Order No ND6</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="SoNumber"
                      :options="optionSoNo"
                      :multiple="true"
                      track-by="code"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="200"
                      :limit="5"
                      placeholder="Type to search"
                      :custom-label="customLabel2"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Export Status</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="statusSelected"
                      :options="optionStatus"
                      :multiple="false"
                      track-by="id"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="200"
                      :limit="5"
                      placeholder="Type to search"
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class="float-right">
                      <vs-button
                        color="grey"
                        class="mx-2"
                        title="Filter Data"
                        @click="handleFilter()"
                        ><i class="fa fa-filter"></i> Filter</vs-button
                      >
                      <vs-button
                        color="primary"
                        title="Export Filtered Data"
                        class="mx-2"
                        @click="handleDownloadAll()"
                        >Export All</vs-button
                      >
                    </div>
                  </div>
                </div>                
                <data-table-export
                  :territoryIDs="this.territoryIDs"
                  :status="this.status"
                  :invDateFrom="this.invDateFrom"
                  :invDateTo="this.invDateTo"
                  :soNumberNd6="this.soNumberNd6"
                  :invNumber="this.invNumber"
                  :draw="draw"
                ></data-table-export>
              </div>
            </vs-tab>
            <vs-tab label="History Export">
              <data-table-history></data-table-history>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>      
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DataTableExport from "./DataTableExport.vue";
import DataTableHistory from "./DataTableHistory.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    DataTableExport,
    DataTableHistory,
    DateRangePicker,
    Datepicker
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      draw: 0,
      territoryIDs: "0",
      supplierIDs: "0",
      status: "All Status",
      statusSelected: [{id: "'All Status'", code: "-", name: "All Status"}],
      invDateFrom: null,
      invDateTo: null,
      invNumber: "All",
      soNumberNd6: "All",
      territory: [
        {
          id: 0,
          code: "",
          name: "",
        }
      ],
      optionTerr: [
        {
          id: 0,
          code: "",
          name: "",
        }
      ],
      InvNumber: [{code: "All"}],
      optionInvNo: [{code: "All"}],
      SoNumber: [{code: "All"}],
      optionSoNo: [{code: "All"}],
      optionStatus: [
        {id: "'All Status'", code: "-", name: "All Status"},
        {id: "'Not Yet'", code: "-", name: "Not Yet"},
        {id: "'Exported'", code: "-", name: "Exported"},
      ],
    };
  },
  methods: {
    handleDownloadAll(){
      try {
        this.$vs.loading();
        this.$http.get(`/api/v1/billing-cmi/generateAll`, {
          params: {
            territoryIDs: this.territoryIDs,
            status: this.status,
            invDateFrom: this.invDateFrom ? moment(this.invDateFrom).format("YYYY-MM-DD") : null,
            invDateTo: this.invDateTo ? moment(this.invDateTo).format("YYYY-MM-DD") : null,
            soNumberNd6: this.soNumberNd6,
            invNumber: this.invNumber,
          }
        })
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "primary",
              title: "Success",
              text: "Please wait background process is running...",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
              time: 3000,
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Failed",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
              time: 10000,
            });
          }
        })
      } catch (error) {
        this.$vs.loading.close();
        console.error("Error downloading file:", error.message);
        this.$vs.notify({
          color: "danger",
          title: "Failed",
          text: error.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
          time: 10000,
        });
      }
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("YYYY-MM-DD");
      }
      return a;
    },
    customLabel(val) {
      if (val) {
        return val.code === "-" ? `${val.name}` :`(${val.code}) ${val.name}`;
      }
    },
    customLabel2(val) {
      if (val) {
        return val.code;
      }
    },
    getOptionInvNo() {
      this.$http.get("/api/v1/billing-cmi/getINV").then((resp) => {
        this.optionInvNo = [{ code: "All" }];
        this.optionInvNo = [...this.optionInvNo, ...resp.data.records.map(item => ({ code: item.code }))];
        this.InvNumber = [this.optionInvNo[0]];
      });
    },
    getOptionSoNo() {
      this.$http.get("/api/v1/billing-cmi/getSO").then((resp) => {
        this.optionSoNo = [{ code: "All" }];
        this.optionSoNo = [...this.optionSoNo, ...resp.data.records.map(item => ({ code: item.code }))];
        this.SoNumber = [this.optionSoNo[0]];
      });
    },    
    getOptionTerritory() {
      this.$http.get("/api/v1/master/territory").then(resp => {
        this.optionTerr = [...resp.data.records];
        // this.optionTerr = [{
        //     "id": this.$store.state.user.currentUser.territory_id,
        //     "code": "-",
        //     "name": "All Territory",
        // }];
        const selectedTerritory = this.optionTerr.find(territory => territory.id == this.$store.state.user.currentUser.territory_id);
        this.territory = selectedTerritory ? [selectedTerritory] : [];
      });
    },
    handleClose() {
      this.detail = false;
    },
    handleOpen() {
      this.detail = true;
    },
    handleFilter() {
      this.draw++;
    }
  },
  watch: {
    SoNumber: {
      handler(newVal, oldVal) {
        const codes = newVal.map(item => item.code);
        this.soNumberNd6 = codes.join(',');
        if (codes[0] == "All" && codes.length > 1) {
          this.SoNumber.shift()
        } else if(codes.includes("All") && codes.length > 1) {
          this.soNumberNd6 = "0"
          this.SoNumber = [{
            "code": "All",
          }];
        }
      },
      deep: true
    },
    InvNumber: {
      handler(newVal, oldVal) {
        const codes = newVal.map(item => item.code);
        this.invNumber = codes.join(',');
        if (codes[0] == "All" && codes.length > 1) {
          this.InvNumber.shift()
        } else if(codes.includes("All") && codes.length > 1) {
          this.invNumber = "0"
          this.InvNumber = [{
            "code": "All",
          }];
        }
      },
      deep: true
    },
    territory: {
      handler(newVal, oldVal) {
        const ids = newVal.id;
        if (ids) {
          this.territoryIDs = ids.toString();
        }
        if (ids == 0 && ids.length > 1) {
          this.territory.shift()
        } 
        // else if(ids == 0 && ids.length > 1) {
        //   this.territoryIDs = "0"
        //   this.territory = [{
        //     "id": 0,
        //     "code": "-",
        //     "name": "All Territory",
        //   }];
        // }
      },
      deep: true
    },
    statusSelected: {
      handler(newVal, oldVal) {
        console.log("=================")
        console.log(newVal)
        // const ids = newVal.map(item => item.id);
        this.status = newVal.name;
        // if (ids[0] == "'All'" && ids.length > 1) {
        //   this.statusSelected.shift()
        // } else if(ids.includes("'All'") && ids.length > 1) {
        //   this.status = "'All'"
        //   this.statusSelected = [{id: "'All'", code: "-", name: "All Status"}]
        // }
      },
      deep: true
    }
  },
  mounted() {
    this.getOptionTerritory()
    this.getOptionSoNo()
    this.getOptionInvNo()
    // console.log(this.$store.state.user.currentUser)
    // const today = new Date();
    // this.filteDate.startDate = new Date(
    //   today.getFullYear(),
    //   today.getMonth() - 1,
    //   1
    // );
    // this.filteDate.endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  }
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
